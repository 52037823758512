<template>
  <div>
    <!-- <v-app-bar app color="red darken-4" dark dense>
      <v-app-bar-nav-icon @click.stop="$emit('drawerControl')"></v-app-bar-nav-icon>
      <v-toolbar-title>Ticket</v-toolbar-title>
      <v-spacer></v-spacer>
      <slot name="action"></slot>

      <v-btn icon @click.stop="add()">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-app-bar>-->

    <v-container>
      <page-title title="Ticket"></page-title>

      <v-card>
        <v-tabs v-model="tab">
          <v-tab v-for="(item, i) in tabs" :key="i">{{ item.title }}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="(item, x) in tabs" :key="x">
            <MyTableData :status="item.value" :title="item.title"></MyTableData>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
  </div>
</template>


<script>
import MyTableData from "./MyTableData.vue";
export default {
  name: "Ticket",

  components: {
    MyTableData,
  },

  data() {
    return {
      tabs: [
        { title: 'OPEN', value: 'open' },
        { title: 'CUSTOMER REPLY', value: 'customer_reply' },
        { title: 'ANSWERED', value: 'answered' },
        { title: 'CLOSED', value: 'closed' },
      ],
      tab: this.$store.state.activeTab['Sales.Ticket.Index'],
    };
  },

  watch: {
    tab(newVal) {
      this.$store.state.activeTab['Sales.Ticket.Index'] = newVal
    }
  },

  mounted() {
    this.requiredLogin();
  },
};
</script>